import I18n from 'common/i18n';
import { FieldT, MetadataTemplate } from 'common/types/metadataTemplate';
import { TableQualifier } from 'common/types/soql';
import { ActionTypes, Dispatcher } from 'metadataTemplates/store';
import React from 'react';
import { connect } from 'react-redux';
import { ForgeRadio } from '@tylertech/forge-react';
const t = (k: string, options: { [key: string]: any } = {}) =>
  I18n.t(k, { scope: 'metadata_templates', ...options });

export const PublicPrivateEditor: React.FunctionComponent<Props> = ({ field, busy, updatePublicPrivate }) => {
  if (!field.is_builtin) {
    return (
      <div
        role="radiogroup"
        aria-label={t('radio_option_public')}
        className="radio-groups"
        data-testid="field-editor-visibility-radio"
      >
        <ForgeRadio>
          <input
            type="radio"
            id="radio-private"
            name="visibilityRadios"
            value="private"
            onClick={() => updatePublicPrivate(true)}
            onChange={() => { }} // Required to silence a console warning
            checked={field.private}
            data-testid="field-editor-visibility-radio-private"
          />
          <label htmlFor="radio-private">{t('private')}</label>
        </ForgeRadio>
        <ForgeRadio>
          <input
            type="radio"
            id="radio-public"
            name="visibilityRadios"
            value="public"
            onClick={() => updatePublicPrivate(false)}
            onChange={() => { }} // Required to silence a console warning
            checked={!field.private}
            data-testid="field-editor-visibility-radio-public"
          />
          <label htmlFor="radio-public">{t('public')}</label>
        </ForgeRadio>
      </div>
    );
  }
  return null;
};

interface ExternalProps {
  template: MetadataTemplate;
  qualifier: TableQualifier;
  field: FieldT;
  busy: boolean;
}
interface DispatchProps {
  updatePublicPrivate: (isPrivate: boolean) => void;
}

type Props = ExternalProps & DispatchProps;

const mergeProps = (
  stateProps: undefined,
  { dispatch }: { dispatch: Dispatcher },
  extProps: ExternalProps
): Props => {
  return {
    ...extProps,
    updatePublicPrivate: (isPrivate) => {
      dispatch({
        type: ActionTypes.FieldVisibilityChanged,
        templateName: extProps.template.name,
        qualifier: extProps.qualifier,
        field: extProps.field,
        isPrivate
      });
    }
  };
};

// a null mapDispatchToProps results in passing { dispatch },
// but this case is not covered in the types in Connect.
// @ts-expect-error TS(2769) FIXME: No overload matches this call.
export default connect(null, null, mergeProps)(PublicPrivateEditor);
